import * as yup from "yup";
import { phoneRegex } from "./constants";
import axios from "axios";

export const validationSchema = {
  password: yup.string().required("Password is required"),
  dynamic: () =>
    yup
      .string()
      .required(
        "This field must not be empty"
      ),
  dynamicNumber: () =>
    yup
      .number()
      .required(
        "This field must not be empty and must be a number"
      ),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email address is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords do not match"),
  phoneNumber: yup.string().matches(phoneRegex, "Phone number is not valid"),
};

// Upload image
export const UploadImage = async (image: any) => {

  const formData = new FormData();
  formData.append("file", image);
  formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "ml_default");

  const res = await axios.post(
    `https://api.Cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload`,
    formData
  );
  const data = res.data;
  return data;
};


export const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const beforeUpload = (file: any) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    return "You can only upload JPG/PNG file!";
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    return "Image must smaller than 2MB!";
  }
  return isJpgOrPng && isLt2M;
};