
import styles from './product.module.scss'
import refresh from '../../assets/icons/refresh.svg'
import exportIcon from '../../assets/icons/export.svg'
import ProductBox from './productBox'
import { useContext, useEffect, useState } from 'react'
import ProductContext from '@/context/Product/ProductContext'

const Products = () => {

  const {fetchAllProducts, allProducts}  = useContext(ProductContext)

  useEffect(() => {
    fetchAllProducts()
  }, [])
  

  const userProducts = allProducts?.productHuntProjects



  const [openModalId, setOpenModalId] = useState(null);


  const [rotate, setRotate] = useState(false)

  const handleRefresh = () => {
    setRotate(true)
  }

  const handleClick = () => {
    setRotate(false)
  }

  return (
    <div className={styles['product__hunt']}>
      <header>
        <h1>Products</h1>
        <p>Manage your products etc...</p>
      </header>

      <nav>
        {/* <Form> */}
        <div className={styles['search__box']}>
          <input type='text' placeholder='Search Products...' />
          <div className={styles['search']}>
            <p>Search</p>
          </div>
        </div>

        <select name='menu' id='menu'>
          <optgroup>
            <option value='ALL'>All status ({userProducts?.length})</option>
            <option value='VIEW PRODUCT'>view product</option>
            <option value='APPROVE PRODUCT'>Approve product</option>
            <option value='DENY PRODUCT'>Deny product</option>
          </optgroup>
        </select>

        {/* refresh */}
        <div className={styles['refresh']} onClick={handleRefresh}>
          <div className={`anime ${rotate ? styles.rotate : ''}`}>
            <img src={refresh} alt='refresh' />
          </div>
          <p>Refresh</p>
        </div>

        {/* export */}
        <div className={styles['export']} onClick={handleClick}>
          <div>
            <img src={exportIcon} alt='export' />
          </div>
          <p>Export</p>
        </div>
        {/* </Form> */}
      </nav>


      
      {userProducts?.length === 0 ? (
        <div className={styles.emptyContainer}>  
          {/* <p> There are no products yet <span  onClick={handleCreate}>click here</span> to launch a product.</p> */}
          <p> There are no products yet</p>
        </div>
      ) : (
        <section className={styles['flex__group']}>
          {userProducts?.map((data, index) => (
            <ProductBox 
              key={index}
              {...data} 
              openModalId={openModalId}
              setOpenModalId={setOpenModalId}
            />
          ))}
        </section>
      )}



    </div>
  )
}

export default Products
